
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { NextSeo } from 'next-seo'
import { Button } from '../components/Button'

import useTranslation from 'next-translate/useTranslation'

export const NotFound = () => {
  const { lang } = useTranslation()

  const headline = lang === 'en' ? 'Page not found' : 'Seite nicht gefunden'
  const text =
    lang === 'en'
      ? 'The content you are looking for does not exist.'
      : 'Den gesuchten Inhalt konnten wir leider nicht finden.'
  const buttonText =
    lang === 'en' ? 'Go back to the homepage' : 'Zurück zur Startseite'

  return (
    <>
      <NextSeo
        noindex
        nofollow
        title="404"
        description="Den gesuchten Inhalt konnten wir leider nicht finden."
        openGraph={{
          type: 'website',
          title: '404',
          description: 'Den gesuchten Inhalt konnten wir leider nicht finden.',
        }}
      />
      <section className="container py-32 mx-auto">
        <h1>{headline}</h1>
        <p>{text}</p>
        <div className="mt-16">
          <Button href="/" as="a">
            {buttonText}
          </Button>
        </div>
      </section>
    </>
  )
}

export default NotFound


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  